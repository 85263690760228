import SectionTitle from '@src/pages/common/SectionTitle';
import Button from '@standby/dike_ui/components/atoms/Button/Button';
import { observer } from 'mobx-react';

import vm from '../../AdminSettingVm';
import AuthorityManagementTable from './components/AuthorityManagementTable';
import st from './style.module.scss';

const AuthorityManagementList = observer(() => {
  return (
    <div className={st.container}>
      <SectionTitle>권한 관리</SectionTitle>
      <div className={st.list}>
        {vm.managers.length === 0 ? (
          <AuthorityManagementTable />
        ) : (
          [
            vm.managers.some((manager) => manager.authorityType === 'GENERAL_MANAGER') ? (
              <AuthorityManagementTable
                manager={vm.managers.find((manager) => manager.authorityType === 'GENERAL_MANAGER')}
              />
            ) : null,
            ...vm.managers
              .filter((manager) => manager.authorityType !== 'GENERAL_MANAGER')
              .map((manager) => <AuthorityManagementTable manager={manager} key={manager.userId} />),
          ].filter(Boolean)
        )}
      </div>
      <div className={st.btnArea}>
        <Button size="large" type="button" color="primary400" className={st.submit} onClick={() => vm.save()}>
          수정사항 저장하기
        </Button>
      </div>
    </div>
  );
});

export default AuthorityManagementList;
