import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';

class BasicVm {
  async load() {
    await businessRegistrationService.loadBusinessRegistrationStatus();
  }
  get businessRegistrationStatuses() {
    return businessRegistrationModel.businessRegistrationStatuses;
  }
  get statusType() {
    let statusType = '';

    statusType =
      businessRegistrationModel.businessRegistrationStatuses[
        businessRegistrationModel.businessRegistrationStatuses.length - 1
      ]?.status;

    return statusType;
  }
  get pageType() {
    return businessRegistrationModel.pageType;
  }
  clearPageType() {
    businessRegistrationModel.pageType = null;
  }
  get bankbooks() {
    return businessRegistrationModel.bankbooks;
  }
  get viewingPeriodFinishedYn() {
    return businessRegistrationModel.viewingPeriodFinishedYn;
  }
}

export default new BasicVm();
